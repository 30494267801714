import {animate, animateChild, group, keyframes, query, state, style, transition, trigger} from '@angular/animations';
import {contextColors} from './theme-colors';

export const transitionEasing = 'cubic-bezier(0.165, 0.84, 0.44, 1)';
export const transitionEasingSharp = 'cubic-bezier(0.19, 1, 0.22, 1)';
export const transitionEasingElastic = 'cubic-bezier(0.175, 0.885, 0.32, 1.275)';
export const transitionDurations = {
    normal: '350ms',
    long: '550ms',
    longer: '1000ms',
};
export const stateTransition = `${transitionDurations.normal} ${transitionEasing}`;
export const longTransition = `${transitionDurations.long} ${transitionEasing}`;
export const longerTransition = `${transitionDurations.longer} ${transitionEasing}`;

export const pulseAnimationTrigger = trigger('pulse', [
    transition('void => *', animate('0ms')),
    transition('* => *', animate(longerTransition, keyframes([
        style({offset: 0}),
        style({transform: 'scale(1.4)', backgroundColor: '#9CECE3', offset: 0.33}),
        style({offset: 1}),
    ]))),
]);

export const selectPulseAnimationTrigger = trigger('selectPulse', [
    // transition('void => *', animate('0ms')),
    transition('* => *', group([
        query('@*', animateChild(), {optional: true}),
        animate(100, style({backgroundColor: contextColors.brand_2})),
        animate(`${transitionDurations.long} 100ms linear`),
    ])),
]);

export const selectCaptionPulseAnimationTrigger = trigger('selectCaptionPulse', [
    // transition('void => *', animate('0ms')),
    transition('* => *', [
        animate(50, style({transform: 'translateX(-10px)', opacity: 0})),
        animate(1, style({transform: 'translateX(10px)', opacity: 0})),
        animate(`${transitionDurations.normal} ${transitionEasingElastic}`),
    ]),
]);

export const overlayVisibleTrigger = trigger('overlayVisible', [
    transition('void => *', [
        style({opacity: '0'}),
        animate(100),
        animateChild(),
    ]),
    transition('* => void', [
        animate(100, style({opacity: '0'})),
        animateChild(),
    ]),
]);

export const fadeInOutTrigger = trigger('fadeInOut', [
    transition('void => *', [
        style({opacity: '0'}),
        animate(stateTransition),
    ]),
    transition('* => void', [
        animate(stateTransition, style({opacity: '0'})),
    ]),
]);

export const flyInOutTrigger = trigger('flyInOut', [
    transition('void => *', [
        style({transform: 'translateX(-10%)', opacity: '0'}),
        animate(stateTransition),
    ]),
    transition('* => void', [
        animate(stateTransition, style({transform: 'translateX(10%)', opacity: '0'})),
    ]),
]);

export const flyInTrigger = trigger('flyIn', [
    transition('void => *', [
        style({transform: 'translateX(-15px)', opacity: '0'}),
        animate(stateTransition),
    ]),
    transition('* => void', [
        animate(stateTransition, style({position: 'absolute', transform: 'translateX(-5px)', opacity: '0'})),
    ]),
]);

export const slideDownUpTrigger = trigger('slideDownUp', [
    transition('void => *', [
        style({height: '30px', marginTop: '-30px', marginBottom: '0', opacity: '0'}),
        animate(stateTransition),
    ]),
    transition('* => void', [
        animate(stateTransition, style({height: '30px', marginTop: '-30px', marginBottom: '0', opacity: '0'})),
    ]),
]);

const verticallyClosed = {height: '0', marginTop: '0', marginBottom: '0', opacity: '0', overflowY: 'hidden'};

export const openVerticallyTrigger = trigger('openVertically', [
    transition('void => *', [
        style(verticallyClosed),
        animate(stateTransition),
    ]),
    transition('* => void', [
        animate(stateTransition, style(verticallyClosed)),
    ]),
]);

export const toggleOpenTrigger = trigger('toggleOpen', [
    state('void', style(verticallyClosed)),
    state('false', style(verticallyClosed)),
    transition('* => *', animate(stateTransition)),
    transition('* => true', [
        animate(stateTransition, style({height: '*', marginTop: '*', marginBottom: '*', opacity: '*', overflowY: 'hidden'})),
    ]),
]);
